import React from 'react';
import { object } from 'prop-types';
import Layout from '../../components/Layout/Layout';
//import Fade from 'react-reveal/Fade';

import Image1 from '../../images/campaigns/services/Portada.png';
import Step1 from '../../images/campaigns/services/step1.png';
import Step2 from '../../images/campaigns/services/step2.png';
import Step3 from '../../images/campaigns/services/step3.png';
import Step4 from '../../images/campaigns/services/step4.png';
import Icon1 from '../../images/campaigns/services/icon01.png';
import Icon2 from '../../images/campaigns/services/icon02.png';
import Icon3 from '../../images/campaigns/services/icon03.png';
import Icon4 from '../../images/campaigns/services/icon04.png';
import Icon5 from '../../images/campaigns/services/icon05.png';
import Icon6 from '../../images/campaigns/services/icon06.png';
import caseStudies1 from '../../images/campaigns/services/rog-case.png';
import caseStudies2 from '../../images/campaigns/services/ijnet-case.png';
import caseStudies3 from '../../images/campaigns/services/ip-case.png';


//logos
import Visa from '../../images/campaigns/services/visabox-logo.png';
import Rog from '../../images/campaigns/services/rogbox-logo.png';
import WorldBank from '../../images/campaigns/services/worlbankbox-logo.png';
import P12 from '../../images/campaigns/services/p12box-logo.png';
import Ijnet from '../../images/campaigns/services/ijnetbox-logo.png';
import ElDoce from '../../images/campaigns/services/eldocebox-logo.png';
import Ip from '../../images/campaigns/services/ipbox-logo.png';
import ElNueve from '../../images/campaigns/services/elnuevebox-logo.png';

// tech
import D9 from '../../images/campaigns/services/drupal-tech.png';
import Next from '../../images/campaigns/services/next-tech.png';
import Docker from '../../images/campaigns/services/docker-tech.png';
import ReactLogo from '../../images/campaigns/services/react-tech.png';
import Php from '../../images/campaigns/services/php-tech.png';
import Angular from '../../images/campaigns/services/angular-tech.png';
import Node from '../../images/campaigns/services/node-tech.png';
import Git from '../../images/campaigns/services/git-tech.png';

const CampaignsServices = ({ location }) => {

  return (
    <Layout className="campaigns-services" location={location}>
      <div className="content-inner">
        {/* section */}
        <section className="section-campaigns-service section-service-1">
          <div className="section-service--inner">
            <div className="left">
              <p><strong> The greatest value we have is our experience</strong>, it is from there where we can offer our customers cost-effective solutions, a wide technological stack and a highly qualified team available to integrate efficiently into their projects.</p>
              <p><strong> Count on our team for short or long term solutions, to add knowledge and expertise to a project, or to rely on us to migrate to a new technology. </strong></p>
              <a className="btn btn-big" href="/contact">Get Started Today</a>
            </div>
            <div className="right">
              <img className="" src={Image1} alt="" />
            </div>
          </div>
        </section>
        {/* section END*/}
        <section className="section-campaigns-service  section-service-2">
          <div className="section-service--inner">
          <h3>Boost your projects pace, scale quickly while reducing costs.</h3>
          </div>
        </section>
        {/* section */}
        <section className="section-campaigns-service section-service-3">
          <div className="section-service--inner">
            <h3 className='section-title arrow-title'>Clients<div className="arrow"></div></h3>
            <div className="logos-list">
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={WorldBank} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Rog} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Visa} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={P12} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Ijnet} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={ElDoce} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Ip} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={ElNueve} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section END*/}
        {/* section */}
        <section className="section-campaigns-service section-service-4">
          <div className="section-service--inner ">
            <h3 className='section-title arrow-title'>Technologies<div className="arrow"></div></h3>
            <p>Our technological stack is designed to offer efficient, accessible, fast and comprehensive solutions. We have seasoned specialists in the most cutting-edge technologies on the market.</p>
            <div className="logos-list">
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={D9} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Next} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={ReactLogo} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Php} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Angular} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Node} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Git} alt="" />
                </div>
              </div>
              <div className="logo-item">
                <div className="logo-item-inner">
                  <img className="logo" src={Docker} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section END*/}
        {/* section */}
        <section className="section-campaigns-service section-service-5">
          <div className="section-service--inner">
            <div className="section-title-wrapper">
              <h3 className='section-title arrow-title'>Why Staff Augmentation?<div className="arrow"></div></h3>
            </div>

            <div className="icon-list">
              <div className="icon-item">
                <div className="icon-item-inner">
                  <img className="" src={Icon1} alt="" />
                  <h3>Knowledge Transfer.</h3>
                </div>
              </div>
              <div className="icon-item">
                <div className="icon-item-inner">
                  <img className="" src={Icon2} alt="" />
                  <h3>Flexibility & scale agility</h3>
                </div>
              </div>
              <div className="icon-item">
                <div className="icon-item-inner">
                  <img className="" src={Icon3} alt="" />
                  <h3>No recruitment burden</h3>
                </div>
              </div>
              <div className="icon-item">
                <div className="icon-item-inner">
                  <img className="" src={Icon4} alt="" />
                  <h3>Cost-Effective solutions</h3>
                </div>
              </div>
              <div className="icon-item">
                <div className="icon-item-inner">
                  <img className="" src={Icon5} alt="" />
                  <h3>Gain speed and adaptability</h3>
                </div>
              </div>
              <div className="icon-item">
                <div className="icon-item-inner">
                  <img className="" src={Icon6} alt="" />
                  <h3>Fill gaps in your workforce</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section END*/}
        {/* section */}
        <section className="section-campaigns-service section-service-6">
          <div className="section-service--inner">
            <h3 className='section-title arrow-title'>How does it work?<div className="arrow"></div></h3>
            <div className="step-wrapper">
              <div className="step-numbers">
                <div className="step-number">
                  <div className="step-number-inner">01</div>
                </div>
                <div className="step-number">
                  <div className="step-number-inner">02</div>
                </div>
                <div className="step-number">
                  <div className="step-number-inner">03</div>
                </div>
                <div className="step-number">
                  <div className="step-number-inner">04</div>
                </div>
              </div>
              <div className="steps">
                <div className="step step-1">
                  <div className="step-inner">
                    <img className="" src={Step1} alt="" />
                    <h3>Requirements Analysis</h3>
                  </div>
                </div>
                <div className="step step-2">
                  <div className="step-inner">
                    <img className="" src={Step2} alt="" />
                    <h3>Select the adequate specialists</h3>
                  </div>
                </div>
                <div className="step step-3">
                  <div className="step-inner">
                    <img className="" src={Step3} alt="" />
                    <h3>Specialist onboarding</h3>
                  </div>
                </div>
                <div className="step step-4">
                  <div className="step-inner">
                    <img className="" src={Step4} alt="" />
                    <h3>Analysis & Follow Up</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="center">
              <center><a className="btn btn-big" href="/contact">Contact US!</a></center>
            </div>
          </div>
        </section>
        {/* section END*/}
        {/* section */}
        <section className="section-campaigns-service section-service-7">
          <div className="section-service--inner">
            <h3 className='section-title arrow-title'>Case Studies<div className="arrow"></div></h3>
            <p>We work with international clients on a wide variety of projects . You can see some of them in our Portfolio.</p>
            <div className="list">
              <div className="item-list">
                <img className="" src={caseStudies1} alt="" />
                <h3>Fully Responsive</h3>
                <p>The final result is a fully responsive work of art, content-editor friendly, brand representative. <a href='/projects/realty-one-group'>Read more</a></p>
              </div>
              <div className="item-list">
                <img className="" src={caseStudies2} alt="" />
                <h3>Multi Language</h3>
                <p>Great multi-language news site turned up to be fast, light and very easy to use. <a href='/projects/international-journalists-network'>Read more</a></p>
              </div>
              <div className="item-list">
                <img className="" src={caseStudies3} alt="" />
                <h3>Full AMP Site</h3>
                <p>Website for a new Breaking News TV channel using a user-first approach with Drupal & NextJS. <a href='/projects/ip-digital'>Read more</a></p>
              </div>
            </div>
            <div className="center">
              <center><a className="btn btn-big" href="/projects">Success Stories</a></center>
            </div>
          </div>
        </section>
        {/* section END*/}
      </div>
    </Layout>
  );
};

CampaignsServices.propTypes = {
  location: object
};

export default CampaignsServices;
